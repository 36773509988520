import { fetchOnce, fetchRetry } from '../modules/fetch';

export class FavoritesService {

    static async add(productID) {
        let response = await fetchOnce(`${dynamicData.page.virtualRoot}api/favorites/add?productID=${productID}`, {
            method: 'POST'
        });
        return response.json();
    }

    static async remove(productID) {
        let response = await fetchOnce(`${dynamicData.page.virtualRoot}api/favorites/remove?productID=${productID}`, {
            method: 'DELETE'
        });
        return response.json();
    }
}
